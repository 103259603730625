<template>
  <div class="bs-payments-container">
    <MainPayments
      ref="mainPaymentsRef"
      :payments="mainPaymentsList"
      :payment-info="paymentInfo"
      :language="language"
      :payment-config="paymentConfig"
      :payment-items-config="paymentItemsConfig"
      :checkout-info="checkoutInfo"
      :bsPaymentAction="handleBsPaymentAction"
      @select="handleSelectEvt"
    />
    <!--  -->
    <template v-if="isMounted">
      <LazyMount>
        <!-- bnpl弹窗 -->
        <BnplPayments
          :visible="paymentsStore.bnplInfo.visibleBnpl"
          :payments="paymentsStore.bnplInfo.payments"
          :language="language"
          :homogenization-pay="homogenizationPay"
          @confirm-select="handleBnplConfirmSelect"
        />
      </LazyMount>
      <!-- 二级弹窗 -->
      <LazyMount>
        <BankSelect
          :visible="paymentsStore.bankSelectInfo.visible"
          :bank-list="paymentsStore.bankSelectInfo.banks"
          :default-code="paymentsStore.bankSelectInfo.selectedBank && paymentsStore.bankSelectInfo.selectedBank.code"
          :payment-code="paymentsStore.bankSelectInfo.paymentCode"
          :language="language"
          @close="handleCloseBankSelect"
          @select="handleSelectedBankInfo"
        />
      </LazyMount>

      <!-- capitec弹窗 -->
      <InfoCollectionPopup
        :language="language"
        :collection-info="paymentsStore.editablePaymentInfo"
        phoneCountryNum="+27"
        @submit="handleCapitecSubmit"
        @pay-now="handlePayNow"
      />
      <LazyMount>
        <ChooseTokenDrawer
          :visible.sync="paymentsStore.isShowTokenDrawer"
          :language="language"
          :payment-list="mainPaymentsList"
          :payment-config="paymentConfig"
        />
      </LazyMount>
      <!-- ddc收集 -->
      <DdcModule
        ref="ddcRef"
        :action="ddcParams.action"
        :jwt="ddcParams.jwt"
        :cardBin="ddcParams.cardBin"
      />
      <CvvDrawer
        ref="cvvRef"
        :language="language"
        :payments-store="paymentsStore"
        @pay-now="handlePayNow"
      />
      <GooglePayDialog
        :visible="paymentsStore.showGooglePayDialog"
        @close="handleCloseGooglePayDialog"
      />
      <LazyMount mount-prop-name="show">
        <ApplePayDialog
          :show="paymentsStore.showApplePayDialog"
          @start-pay="handlePayNow"
          @close="handleCloseApplePayDialog"
        />
      </LazyMount>
      <PrePay
        ref="prePayRef"
        :bsPaymentAction="handleBsPaymentAction"
      >
        <template #pre-pay-modal-footer="{ scene, payNow }">
          <slot
            name="checkout-footer"
            :scene="scene"
            :pay-now="payNow"
            :isNotShowPriceDetail="false"
          ></slot>
        </template>
      </PrePay>
      <!--  -->
      <SpecialChannelModule
        ref="specialChannelModuleRef"
      />

      <LazyMount>
        <PayUpgradeAppTips
          :visible="upgradeAppTipsDialogProps.visible"
          :tips="upgradeAppTipsDialogProps.tips"
          :confirmText="upgradeAppTipsDialogProps.confirmText"
          @confirm="handleUpgradeAppTipsConfirm"
          @cancel="handleUpgradeAppTipsCancel"
        />
      </LazyMount>

      <InstallmentListDrawer
        ref="installmentsRef"
        :language="language"
        :bsPaymentAction="handleBsPaymentAction"
        :now-currency="checkoutInfo?.orderCurrencyInfo && checkoutInfo.orderCurrencyInfo.code"
        :now-cvv="nowCvv"
        :now-installment="nowInstallment"
        :payments-store="paymentsStore"
        :is-now-maestro="isNowMAESTRO"
        @cvv-validate="cvvRefValidateByPay"
        @pay-now="handlePayNow"
      >
        <template #pre-pay-modal-footer="{ scene, payNow }">
          <slot
            name="checkout-footer"
            :scene="scene"
            :pay-now="payNow"
            :isNotShowPriceDetail="true"
          ></slot>
        </template>
      </InstallmentListDrawer>

      <NoInstallmentDialog
        ref="noInstallmentDialogRef"
        :language="language"
        :now-cvv="nowCvv"
        :is-now-maestro="isNowMAESTRO"
        @cvv-validate="cvvRefValidateByPay"
        @pay-now="handlePayNow"
      />
    </template>
    <!-- slot checkout footer -->
  </div>
</template>

<script name="BPayPaymentOptions" setup lang="ts">
import { LazyMount } from '@shein/sui-mobile'
import { provide } from 'vue'
import MainPayments from './MainPayments.vue'
import BnplPayments from './BnplPayments.vue'
import usePayments from '../../../hooks/usePayments'
import { useSelectBank } from '../../../hooks/useSelectBank.ts'
import type { PaymentItemProps } from '../../../types/props'
import type { BankInfo, CollectInfoItem, PaymentItemSelectType, LIST_CONTENT_CHANGE_TYPE } from '../../../types'
import { ref, computed, defineAsyncComponent, onMounted, onActivated, nextTick } from 'vue'
import ChooseTokenDrawer from '../payment-items/token/ChooseTokenDrawer.vue'
import SpecialChannelModule from '../special-channel-module/index.vue'
import PrePay from '../pre-pay/index.vue'
import { useAnalysis } from '../../../common/analysisSource'
import { useValidSelectBank } from '../../../hooks/useItemSelectBank'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import CodVerifyFreePopup from '../cod-verify-free-popup/index.ts'
import { useSelectedPayment } from '../../../hooks/useSelectedPayment'
import { usePaymentConfig, defaultConfig } from '../../../hooks/usePaymentsConfig'
import { useApplePayDialog } from '../../../hooks/useApplePayDialog'
import { useGooglePayDialog } from '../../../hooks/useGooglePayDialog'
import { useScrollToPayment } from '../../../hooks/useScrollToPayment'
import { useUpgradeAppTips } from '../../../hooks/useUpgradeAppTips'
import useCapitecDialog from '../../../hooks/useCapitecDialog'
import { debuggerLog } from '../../../utils'
import { type AbtPosKeysTypes } from '../../../common/constants'
import { type PwaLangKeys } from '../../../common/languages'
import { CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { useSelectedToken } from '../../../hooks/useToken'

const analysisSource = useAnalysis()

provide('analysisInstance', analysisSource)


const BankSelect = defineAsyncComponent(() => import('./BankSelect.vue'))
const InfoCollectionPopup = defineAsyncComponent(() => import('./InfoCollectionPopup.vue'))
const DdcModule = defineAsyncComponent(() => import('../ddc-module/index.vue'))
const CvvDrawer = defineAsyncComponent(() => import('../cvv-drawer/index.vue'))
const GooglePayDialog = defineAsyncComponent(() => import('../googlepay-dialog/index.vue'))
const ApplePayDialog = defineAsyncComponent(() => import('../applepay-dialog/index.vue'))
const PayUpgradeAppTips = defineAsyncComponent(() => import('../upgrade-app-tips/index.vue'))
const InstallmentListDrawer = defineAsyncComponent(() => import('../installments/InstallmentListDrawer.vue'))
const NoInstallmentDialog = defineAsyncComponent(() => import('../installments/NoInstallmentDialog.vue'))

interface BPayPaymentOptionsProps {
  paymentInfo?: {
    fpxShowUpgradationBank?: string[];
    paySafeIconTip?: string;
    paySafeIcons?: string[];
    payments: PaymentItemProps[];
    is_has_oceapay?: number | null;
    paymentSuggestion?: string;
    isUsedThisPayment?: number | null;
    folded_position?: string;
    finalSelectPaymentCode?: string;
    pay_with_title?: string | null;
    force_remember_card?: string | null;
    force_remember_card_tip?: string | null;
    checkout_force_remember_card_tip?: string | null;
  },
  paymentConfig?: Trade_PayToolKit.PaymentConfig;
  checkoutInfo?: Trade_PayToolKit.CheckoutInfo;
  beforeSelect?: (info: Trade_PayToolKit.AssembledToOutsidePaymentInfo) => Promise<boolean> | boolean;
  // currentCheckoutPay?: Trade_PayToolKit.AssembledToOutsidePaymentInfo;
  userInfoGetter?: Trade_PayToolKit.UserInfoGetter;
  couponListState?: Record<string, any>;
  injectFsData?: {
    abtInfo: Record<AbtPosKeysTypes, Trade_PayToolKit.AbtInfo>;
    language: Record<PwaLangKeys, string>;
  }
}

const props = withDefaults(defineProps<BPayPaymentOptionsProps>(), {
  paymentInfo: () => ({
    fpxShowUpgradationBank: [],
    paySafeIconTip: '',
    paySafeIcons: [],
    payments: [],
    is_has_oceapay: null,
    paymentSuggestion: '',
    isUsedThisPayment: null,
    folded_position: '',
    finalSelectPaymentCode: '',
    pay_with_title: null,
    force_remember_card: null,
    force_remember_card_tip: null,
    checkout_force_remember_card_tip: null,
  }),
  paymentConfig: () => defaultConfig,
})


const emit = defineEmits<{
  (event: 'select', info: {paymentInfo: Trade_PayToolKit.AssembledToOutsidePaymentInfo, changeCheckData?: Record<string, any> }): void
  (event: 'payment-info-change', info: {
    paymentInfo: Trade_PayToolKit.AssembledToOutsidePaymentInfo;
    extraInfo: Trade_PayToolKit.AssembledToOutsideExtraInfo,
    changeCheckData?: Record<string, any>,
    payInfoForOrderShow?: Record<string, any>,
  }): void,
  (event: 'list-content-change', info: { type: LIST_CONTENT_CHANGE_TYPE, info?: any }): void,
  (event: 'init-common-method', methods: Record<string, Function>): void,
  (event: 'pay-now', info: Trade_PayToolKit.AssembledToOutsidePaymentInfo & { cvv?: string }): void,
  // (event: 'sdk-bridge-actions', actions: Record<string, any>): void,
  // (event: 'sdk-bridge-action', info: { action: string; payload: Record<string, any> }): void,
  // 内部主动触发外部行为方法
  (event: 'bs-payment-action', info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }): void,
}>()

type EmitsType = typeof emit

const mainPaymentsRef = ref<InstanceType<typeof MainPayments> | null>(null)

const ddcRef = ref()
const specialChannelModuleRef = ref()
const prePayRef = ref()

const cvvRef = ref()
const installmentsRef = ref()
const noInstallmentDialogRef = ref()

const nowCvv = ref('')
const nowInstallment = ref(null)
const isNowMAESTRO = ref(false)

const refs = {
  ddcRef,
  cvvRef,
  installmentsRef,
}

const {
  language,
  paymentsStore,
  ddcParams,
  handleDdc,
  isMounted,
} = usePayments<{
  emit: EmitsType,
  refs: typeof refs
}>(props, { emit, refs })

const { initConfig } = usePaymentConfig()

initConfig(props)

const {
  setSelectedPayment,
  handleAssembledToOutsidePaymentInfo,
  handleSelectedPayment,
  getAssembledInfoByPayment,
  setSelectedPaymentForBinCoupon,
  resetSelectedPayment,
} = useSelectedPayment({ paymentStore: paymentsStore })

const { handleCloseBankSelect, handleSelectedBankInfo } = useSelectBank(paymentsStore)

const {
  needValidSelectBankByPay,
  validateSelectBankByPay,
} = useValidSelectBank(paymentsStore)

const { validateByPay: validateCapitecByPay, needValidCapitec } = useCapitecDialog(paymentsStore)

const {
  upgradeAppTipsDialogProps,
  checkShowUpgradeAppTips,
  handleUpgradeAppTipsConfirm,
  handleUpgradeAppTipsCancel,
  validateByPay: upgradeAppTipsValidateByPay,
} = useUpgradeAppTips({ props, paymentsStore })

const { scrollToPaymentByCode } = useScrollToPayment( paymentsStore )

const { openApplePayDialog, handleCloseApplePayDialog } = useApplePayDialog(paymentsStore)

const { openGooglePayDialog, handleCloseGooglePayDialog } = useGooglePayDialog(paymentsStore)

const { handleSelectedToken, handleEditableInfoChange } = useSelectedToken({ paymentStore: paymentsStore })

const handleCapitecSubmit = ({ paymentCode, bankInfo, collectInfo }: { paymentCode: string, bankInfo?: BankInfo, collectInfo: CollectInfoItem }) => {
  handleSelectedBankInfo({
    paymentCode,
    bankInfo,
    collectInfo,
  })
}

const paymentItemsConfig = computed(() => {
  debuggerLog('paymentItemsConfig======', paymentsStore.paymentItemsConfig)
  return paymentsStore.paymentItemsConfig
})

// 主支付方式列表
const mainPaymentsList = computed(() => {
  return (paymentsStore.payments || []) as PaymentItemProps[]
})

const homogenizationPay = computed(() => (paymentsStore.payments || []).find(item => item?.payments?.length))

const selectedPayment = computed(() => paymentsStore?.selectedPayment || {})
const selectedTokenInfo = computed(() => paymentsStore?.selectedTokenInfo || {})
const nowEditablePaymentInfo = computed(() => paymentsStore?.editablePaymentInfo?.[selectedPayment.value?.code])
const isUseNewCard = computed(() => nowEditablePaymentInfo.value?.isUseNewCard)

const handleSelected = ({
  info,
  isNoNeedEmitSelect,
  changeCheckData,
}: {
  info: Trade_PayToolKit.AssembledToOutsidePaymentInfo;
  isNoNeedEmitSelect?: boolean;
  changeCheckData?: Record<string, any>;
}) => {
  debuggerLog('handleSelected==|||=========|||==', info)
  nextTick(() => {
    handleDdc({ submit: ddcRef.value?.submit, callback: (val = '') => {
      handlePaymentInfoChange({ info, ddcSessionId: val, changeCheckData })
    } })
  })

  if (!isNoNeedEmitSelect) {
    emit('select', {
      paymentInfo: info,
      changeCheckData,
    })
    const paymentIndex = mainPaymentsList.value.findIndex(item => item.code === info.code)
    const foldedPosIndex = +(paymentItemsConfig.value?.foldedPosition || 1) - 1
    const isFolded = paymentIndex > foldedPosIndex
    const isDefault = paymentsStore.paymentSuggestion === info.code
    let isVaulting = '-'
    if (info.code === 'PayPal-GApaypal') {
      isVaulting = info?.sign_account_info?.signUpEmail ? '1' : '0'
    }
    // 是否存在在线支付方式优惠 discountType=2
    const isPayPromotion = info.paymentsPreferentialTips?.some?.(item => +item.type === 2)
    // 一次二次支付选择支付方式埋点上报区分
    if (
      [
        CHECKOUT_TYPE.NORMAL_AGAIN,
        CHECKOUT_TYPE.GIFTCARD_AGAIN,
        CHECKOUT_TYPE.BUYPRIME_AGAIN,
        CHECKOUT_TYPE.XTRA_AGAIN,
      ].includes(props.paymentConfig.checkoutType!)) {
        analysisSource.triggerNotice?.({
          id: 'click_payment_choose:simple',
          data: {
            payment_code: info.code,
            is_vaulting: isVaulting,
          },
        })
    } else {
      analysisSource.triggerNotice?.({
        id: 'click_payment_method.comp_pay-toolkit',
        data: {
          payment_method: info.code,
          is_vaulting: isVaulting,
          is_default: isDefault ? '1' : '0',
          is_pay_promotion: isPayPromotion ? '1' : '0',
          default_type: isDefault ? '1' : '0',
          is_folded: foldedPosIndex && isFolded ? '1' : '0',
          sequence: paymentIndex > -1 ? `${paymentIndex + 1}` : '-',
        },
      })
    }
  }
}

const handleSelectEvt = async (
  { type, paymentItem, manualSelect, triggerSelectHook, changeCheckData, tokenItem, isInitEmit }:
  {
    type: PaymentItemSelectType;
    paymentItem?: Trade_PayToolKit.PaymentInfoItem;
    manualSelect?: boolean;
    triggerSelectHook?: boolean;
    changeCheckData?: Record<string, any>;
    tokenItem?: Record<string, any>;
    isInitEmit?: boolean;
  }) => {
  debuggerLog('handleSelectEvt=====', { type, paymentItem, manualSelect }, paymentsStore?.selectedPayment?.code)
  if (type === 'select-payment') {
    const isBnpl = paymentItem?.payments?.length && paymentItem.payments.length > 0

    if (props.beforeSelect && triggerSelectHook && !isBnpl) {
      const preSelectedPaymentInfo = getAssembledInfoByPayment({
        ...paymentItem,
        manualSelect: !!manualSelect,
      }) as Trade_PayToolKit.AssembledToOutsidePaymentInfo
      const beforeSelectPromise = props.beforeSelect?.(preSelectedPaymentInfo)
      const beforeSelectResult = await beforeSelectPromise
      if (!beforeSelectResult) {
        return
      }
    }

    paymentsStore.updateState('temporaryPayment', paymentItem?.payments?.find(item => item.code === paymentItem?.code) || {})

    if (isBnpl) {
      const nowBnplSelectPayInfo = paymentItem.payments?.find(item => item.code === paymentItem?.code)
      paymentsStore?.updateState('bnplInfo', {
        ...paymentsStore?.bnplInfo,
        visibleBnpl: !manualSelect,
        payments: paymentItem.payments,
        ...nowBnplSelectPayInfo, // 防止默认选中的时候某些字段数据缺失
      })
      if (manualSelect) {
        paymentsStore?.updateState('selectedPayment', {
          manualSelect: !!manualSelect,
          ...paymentItem,
          ...nowBnplSelectPayInfo, // 防止默认选中的时候某些字段数据缺失
        })

        handleSelectedPayment({ manualSelect })
        handleSelected({
          info: handleAssembledToOutsidePaymentInfo(),
        })
        debuggerLog('selectedPayment==>>>>>', paymentsStore?.selectedPayment)
      }
    } else {
      debuggerLog('paymentState==>>>=====', paymentsStore)
      const initTokenInfo = isInitEmit ? {
        isUseNewCard: tokenItem?.isUseNewCard,
      } : {}
      paymentsStore?.updateState('selectedPayment', {
        manualSelect: !!manualSelect,
        ...paymentItem,
        ...initTokenInfo,
      })
      paymentsStore?.updateState('bnplInfo', {
        ...paymentsStore?.bnplInfo,
        selectedPayment: {},
      })
      handleSelectedPayment({ manualSelect })
      handleSelected({
        info: handleAssembledToOutsidePaymentInfo(),
        changeCheckData,
      })
    }
  }

  debuggerLog('tokenItem==>>>=====', type, tokenItem)
  if (type === 'select-token' && paymentItem && tokenItem) {
    const lastSelectedPaymentCode = paymentsStore?.selectedPayment?.code
    paymentsStore?.updateState('selectedPayment', {
        manualSelect: !!manualSelect,
        ...paymentItem,
    })

    paymentsStore?.updateState('selectedTokenInfo', {
        [paymentItem?.code]: tokenItem,
    })

    if (tokenItem?.isUseNewCard) {
      handleEditableInfoChange({
        changeInfo: {
            [paymentItem?.code]: {
                ...(paymentsStore?.editablePaymentInfo?.[paymentItem?.code] || {}),
                card_token_info: {},
                isUseNewCard: true,
            },
        },
        needEmitChangeEvent: true,
      })
    } else {
      handleSelectedToken({
        nowPay: paymentItem,
        tokenItem: tokenItem || {},
      })
    }

    handleSelectedPayment({ manualSelect })
    debuggerLog('selectedPayment==>>>>>', paymentItem?.code, lastSelectedPaymentCode, paymentItem?.code === lastSelectedPaymentCode)
    if (lastSelectedPaymentCode && paymentItem?.code === lastSelectedPaymentCode) {
      emit('payment-info-change', {
        paymentInfo: handleAssembledToOutsidePaymentInfo(),
        extraInfo: {},
      })
    } else {
      handleSelected({
        info: handleAssembledToOutsidePaymentInfo(),
      })
    }
  }
}

const handleBnplConfirmSelect = async (info: Partial<Trade_PayToolKit.SelectedPaymentInfo>) => {
  if (props.beforeSelect) {
    // 关闭bnpl弹窗
    paymentsStore?.updateState('bnplInfo', {
      ...paymentsStore?.bnplInfo,
      visibleBnpl: false,
    })
    const preSelectedPaymentInfo = getAssembledInfoByPayment(info) as Trade_PayToolKit.AssembledToOutsidePaymentInfo
    const beforeSelectPromise = props.beforeSelect?.(preSelectedPaymentInfo)
    const beforeSelectResult = await beforeSelectPromise
    if (!beforeSelectResult) {
      return
    }
  }

  paymentsStore?.updateState('bnplInfo', {
    payments: paymentsStore.bnplInfo.payments || [],
    visibleBnpl: false,
    selectedPayment: info,
  })
  paymentsStore?.updateState('selectedPayment', info)
  handleSelected({
    info: handleAssembledToOutsidePaymentInfo(),
  })
}


interface handlePaymentInfoChangeProps {
  info: Partial<Trade_PayToolKit.AssembledToOutsidePaymentInfo>;
  ddcSessionId?: string;
  changeCheckData?: Record<string, any>;
  extraInfo?: Record<string, any>;
}
const handlePaymentInfoChange = ({
  info,
  ddcSessionId,
  changeCheckData,
  extraInfo = {},
}: handlePaymentInfoChangeProps) => {
  debuggerLog('paymentsStore >>>>||>>', paymentsStore)
  const paymentInfoChangeInfo = {
    ...info,
    ddcSessionId,
  }
  debuggerLog('handlePaymentInfoChange=??==', paymentInfoChangeInfo)
  emit('payment-info-change', {
    paymentInfo: handleAssembledToOutsidePaymentInfo(paymentInfoChangeInfo),
    extraInfo: extraInfo,
    changeCheckData,
  })
}

/**
 * 获取支付前校验方法
 *
 * @param options
 */
const getValidateByPay = (options?: Trade_PayToolKit.ValidateByPayOptions): Trade_PayToolKit.ValidateByPayType | Promise<Trade_PayToolKit.ValidateByPayType> => {

  let tempValidateByPay: (options?: Trade_PayToolKit.ValidateByPayOptions) => Trade_PayToolKit.ValidateByPayType | Promise<Trade_PayToolKit.ValidateByPayType> = () => ({
    validateResult: true,
    scene: '',
  })

  const isInstallmentToken = selectedPayment.value?.code === 'routepay-cardinstallment'
    && selectedPayment.value?.card_token_list?.length > 0
    && selectedPayment.value?.card_token?.id
    && !paymentsStore?.editablePaymentInfo[selectedPayment.value?.code]?.isUseNewCard // 没有点击使用新卡

  // 优先级从低到高排序
  const validatePayList = [
    {
      isNeedValidate: checkShowUpgradeAppTips(selectedPayment.value?.code),
      validateFn: upgradeAppTipsValidateByPay,
    },
    {
      // 如果有银行选择弹窗，需要校验
      isNeedValidate: needValidSelectBankByPay?.(),
      validateFn: validateSelectBankByPay,
    },
    {
      // 需要分期信息的卡分期前置
      isNeedValidate: isInstallmentToken,
      validateFn: installmentsRef.value?.validateByPay,
    },
    {
      // 无需要分期信息的卡分期前置
      isNeedValidate: isInstallmentToken && noInstallmentDialogRef.value?.isUseNoInstallmentDialog(),
      validateFn: noInstallmentDialogRef.value?.validateByPay,
    },
    {
      // 如果有token列表且选中了卡token，则需要校验
      isNeedValidate: selectedPayment.value?.card_token_list?.length > 0
        && selectedPayment.value?.card_token?.id
        && selectedPayment.value?.code !== 'routepay-cardinstallment'
        && !selectedTokenInfo.value?.isUseNewCard, // 二次下单是选中了使用新卡
      validateFn: cvvRef.value?.validateByPay,
    },
    {
      // 如果当前支付方式支持卡前置且未选中token，则需要校验
      isNeedValidate: prePayRef.value?.isUsePrePay?.() && !selectedPayment.value?.card_token?.id && !isUseNewCard.value,
      validateFn: prePayRef.value.validateByPayPrePay,
    },
    {
      isNeedValidate: needValidCapitec(),
      validateFn: validateCapitecByPay,
    },
    {
      // 没有选中支付方式
      isNeedValidate: !selectedPayment.value.code,
      validateFn: () => ({
        validateResult: false,
        scene: 'not-select-payment',
        metric_scene: 'no_payment_id',
        saParams: {
          result_reason: '1_3',
        },
      }),
    },
  ]

  validatePayList.forEach(item => {
    if (item.isNeedValidate) {
      tempValidateByPay = item.validateFn
    }
  })

  debuggerLog('getValidateByPay==|||===>>>', tempValidateByPay)

  return tempValidateByPay?.(options)
}

const getResetBsPayTookitStatusFn = () => {
  debuggerLog('getResetBsPayTookitStatusFn==|||===>>>')
  const isInstallmentToken = selectedPayment.value?.code === 'routepay-cardinstallment'
    && selectedPayment.value?.card_token_list?.length > 0
    && selectedPayment.value?.card_token?.id
    && !paymentsStore?.editablePaymentInfo[selectedPayment.value?.code]?.isUseNewCard // 没有点击使用新卡

  if (isInstallmentToken) {
    nowCvv.value = ''
    isNowMAESTRO.value = false
    nowInstallment.value = null
    installmentsRef.value?.cancel?.()
  }
}

/**
 * 获取当前组件状态
 */
const getComponentStatus = () => {
  return {
    // 目前是否打开了卡支付前置弹窗
    isShowPrePayModal: prePayRef.value?.getPrePayVisibleStatus?.(),
  }
}

const handlerPrePayCouponDrawer = (status) => {
  prePayRef.value?.handlerPrePayCouponDrawer?.(status)
}

// 计价失败回退支付方式
const resetCurrentPayment = (currentCheckoutPay: Trade_PayToolKit.SelectedPaymentInfo) => {
  if (currentCheckoutPay?.payment_code !== selectedPayment.value?.code) {
    const isNoNeedEmitSelect = true // 不需要对外发射select事件
    handleSelected({
      info: currentCheckoutPay!,
      isNoNeedEmitSelect,
    })
  }
}

// 手动触发支付
const handlePayNow = (tempExtraOpts) => {
  debuggerLog('handlePayNow >>>>>>', tempExtraOpts)
  const { cvv = '', installments = {}, isMAESTRO = false } = tempExtraOpts || {}
  nowCvv.value = cvv
  nowInstallment.value = installments
  isNowMAESTRO.value = isMAESTRO
  const resultExtraOpts = {
    ...tempExtraOpts,
    errorCb: (res) => {
      debuggerLog('handlePayNow errorCb >>>>>>', res)
      tempExtraOpts?.errorCb?.()
      nowCvv.value = ''
      isNowMAESTRO.value = false
      nowInstallment.value = null
      installmentsRef.value?.cancel?.()
    },
    // 订单埋点额外需要的字段数据
    payAnalysisInfo: {
      is_installment_default: (paymentsStore?.installmentInfo?.installments || [])?.some(item => item?.default_choose && item?.stage_num === nowInstallment.value),
    },
  }
  emit('pay-now', resultExtraOpts || {})
}

const handleBsPaymentAction = (info: {action: Trade_PayToolKit.BsPaymentAction, payload: Record<string, any>}) => {
  debuggerLog('handleBsPaymentAction====>>>>>', info)
  emit('bs-payment-action', info)
}

// 注册全局挂载到 store 的事件
const initStoreGlobalEvent = () => {
  nextTick(() => {
    paymentsStore?.updateState('handleBsPaymentAction', handleBsPaymentAction)
  })
}

const cvvRefValidateByPay = (data) => {
  cvvRef.value?.validateByPay?.(data)
}

const initEvent = () => {
  if (typeof window === 'undefined') return
  paymentsStore?.paymentEventBus.paymentInfoChange.reset()
  paymentsStore?.paymentEventBus?.setOrderShowInfo?.reset()
  paymentsStore.paymentEventBus.setSelectedPaymentByCode.reset()
  paymentsStore?.paymentEventBus.listContentChange.reset()
  paymentsStore?.paymentEventBus?.paymentValidSendBi?.reset()

  paymentsStore.paymentEventBus.setSelectedPaymentByCode.on((
    { paymentItem, needEmitEvent, triggerSelectHook, changeCheckData, tokenItem, isInitEmit, isResetSelectedTokenInfo } :
    {
      paymentItem: Trade_PayToolKit.PaymentInfoItem,
      needEmitEvent?: boolean,
      triggerSelectHook?: boolean,
      changeCheckData?: Record<string, any>,
      tokenItem?: Record<string, any>,
      isInitEmit?: boolean,
      isResetSelectedTokenInfo?: boolean,
    },
  ) => {
    if (isResetSelectedTokenInfo) {
      paymentsStore?.updateState('selectedTokenInfo', {})
    }
    const nowTokenInfo = (isInitEmit ? selectedTokenInfo.value?.[paymentItem?.code] : tokenItem) || {}
    debuggerLog('setSelectedPaymentByCode==|||=>><>>', paymentItem, nowTokenInfo, isInitEmit, selectedTokenInfo.value)
    handleSelectEvt({ type: 'select-payment', paymentItem, manualSelect: !needEmitEvent, triggerSelectHook, changeCheckData, tokenItem: nowTokenInfo, isInitEmit })

    if (props.paymentConfig?.checkoutType === CHECKOUT_TYPE?.NORMAL_AGAIN && Object.keys(tokenItem || {}).length > 0) {
      handleSelectEvt({ type: 'select-token', tokenItem: nowTokenInfo, paymentItem, manualSelect: !needEmitEvent })
    }
  })
  paymentsStore?.paymentEventBus.paymentInfoChange.on(info => {
    debuggerLog('payment-info-change==|||on===>>>=>>', info, paymentsStore)
    emit('payment-info-change', {
      paymentInfo: handleAssembledToOutsidePaymentInfo(info),
      extraInfo: {
        ...(info?.extraInfo || {}),
      },
    })
  })

  paymentsStore?.paymentEventBus?.setOrderShowInfo?.on(info => {
    emit('bs-payment-action', {
      action: 'setOrderShowInfo',
      payload: {
        payInfoForOrderShow: info || {},
      },
    })
  })

  paymentsStore?.paymentEventBus.listContentChange.on(({ type, info }: { type: LIST_CONTENT_CHANGE_TYPE, info?: any }) => {
    debuggerLog('listContentChange==|||on===>>>=>>', type, info)
    emit('list-content-change', { type, info })
  })
  paymentsStore?.paymentEventBus?.paymentValidSendBi?.on((handlerEmitOrderAnalysisParams) => {
    debuggerLog('PAYMENT_VALID_SEND_BI==|||===>>>', handlerEmitOrderAnalysisParams)
    emit('bs-payment-action', {
      action: 'handlerEmitOrderAnalysis',
      payload: {
        handlerEmitOrderAnalysisParams,
      },
    })
  })
}

const getNowEditablePaymentInfoData = () => {
  return {
    editablePaymentInfo: paymentsStore?.editablePaymentInfo[selectedPayment.value?.code],
    selectedPayment: selectedPayment.value,
    paymentItemsConfig: paymentsStore.paymentItemsConfig,
    selectedDisPlayBankInfo: {
      title: paymentsStore.bankSelectInfo.selectedBank?.name || '',
      logo: paymentsStore.bankSelectInfo.selectedBank?.logo || '',
    },
  }
}

const getEditablePaymentInfoData = () => {
  return paymentsStore?.editablePaymentInfo
}

const handleUpdateEditableInfo = (data) => {
  debuggerLog('handleUpdateEditableInfo==|||===>>>', data)
  mainPaymentsRef.value?.handleChangeEvt?.(data)
}

onMounted(() => {
  initEvent()
  isMounted.value = true
  debuggerLog('BPayPaymentOptions onMounted==', specialChannelModuleRef.value)
  emit('init-common-method', {
    openApplePayDialog,
    openGooglePayDialog,
    openCodFreeVerifyDialog: CodVerifyFreePopup.createPopup.bind(CodVerifyFreePopup),
    handleThreeDSecureModal: (...args) => specialChannelModuleRef.value?.handleThreeDSecureModal?.(...args),
  })

  initStoreGlobalEvent()

  debuggerLog('BPayPaymentOptions onMounted==|||========', prePayRef.value, prePayRef.value?.handlePrePayAction)
})

onActivated(() => {
  debuggerLog('BPayPaymentOptions onActivated==|||========', prePayRef.value, prePayRef.value?.handlePrePayAction)
  initEvent()
  initStoreGlobalEvent()
})

const handlePayToolKitAction = (info: {action: string, payload: Record<string, any>}) => {
  debuggerLog('handlePayToolKitAction==|||===>>>', info)

}

const resetAnalysisExpose = (prefix: string) => {
  if (!prefix) return
  const daEventExpose = analysisSource.getExposeInstance?.()
  daEventExpose?.resetAll?.(prefix)
}

defineExpose({
  setSelectedPayment,
  setSelectedPaymentForBinCoupon,
  scrollToPaymentByCode,
  handlePrePayAction: (...args) => prePayRef.value?.handlePrePayAction?.(...args),
  getComponentStatus,
  handlerPrePayCouponDrawer,
  handlePayToolKitAction: (info: {action: string, payload: Record<string, any>}) => {
    debuggerLog('handlePayToolKitAction==|||===>>>', info)
    return handlePayToolKitAction?.(info)
  },
  resetCurrentPayment,
  getNowEditablePaymentInfoData,
  getEditablePaymentInfoData,
  resetSelectedPayment,
  handleUpdateEditableInfo,
  getValidateByPay,
  getResetBsPayTookitStatusFn,
  resetAnalysisExpose,
})

</script>

<style>
:root {
  --bs-payments-container-main-padding: 0.32rem;
}
</style>
