/* eslint-disable max-lines-per-function */

import { usePaymentsStore, type PaymentsStore } from './usePayments'
// import useCapitecDialog from './useCapitecDialog'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { PaymentItemProps } from '../types/props'
import { debuggerLog } from '../utils'

// 判断浏览器版本，用于兼容applepay btn仅在浏览器版本15+以上才展示
function isSafariVersionGTE15(): boolean {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') return false
  const userAgent = navigator.userAgent || navigator.vendor

  // 判断是否为 Safari 浏览器 (排除 Chrome、Edge 等基于 WebKit 的浏览器)
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)

  if (isSafari) {
    // 提取 Safari 版本号
    const match = userAgent.match(/Version\/(\d+)\.(\d+)?/)
    if (match && match[1]) {
      const majorVersion = parseInt(match[1], 10)
      return majorVersion >= 15 // 判断主版本号是否大于等于 15
    }
  }

  return false // 不是 Safari 或无法解析版本号
}

interface SetSelectedPaymentForBinCouponParams {
  paymentCode: string;
  tokenItem?: PaymentItemProps['card_token_list'][number]
  cb?: () => void,
  changeCheckData?: Record<string, any>,
}

export const useSelectedPayment = ({ paymentStore }: { paymentStore?: PaymentsStore } = {}) => {
  if (!paymentStore) {
    paymentStore = usePaymentsStore()
  }
  // const { openCapitecDialog } = useCapitecDialog(paymentStore)

  const { updateInfo, handleEditableInfoChange } = useEditablePaymentInfo(paymentStore)

  // const { handleSelectedToken } = useSelectedToken({ paymentStore })

  const setSelectedPayment = (params: { paymentInfo: Trade_PayToolKit.SetSelectedPaymentParams, changeCheckData?: Record<string, any>}) => {
    const { paymentInfo = {}, changeCheckData } = params
    const { payment_code, code, bank_code, bank_name, pay_phone, pay_email, display_logo, display_title, signupFlag, use_one_time_sign, card_token_info } =
      paymentInfo
    const nowCode = payment_code || code
    const payment = paymentStore.payments.find((item) => {
      return item.code === nowCode
    })

    if (payment?.enabled) {
      // 填充数据，未来只用这个
      updateInfo(nowCode, {
        bank_code,
        bank_name,
        pay_phone,
        pay_email,
        display_logo,
        display_title,
        signupFlag,
        use_one_time_sign,
        card_token_info,
      })

      // 填充银行信息，后续合并到editablePaymentInfo


      paymentStore.paymentEventBus.setSelectedPaymentByCode.emit({
        paymentItem: payment,
        needEmitEvent: true,
        changeCheckData,
      })
    }
  }

  const resetSelectedPayment = (needEmitEvent: boolean = false) => {
    paymentStore.paymentEventBus.setSelectedPaymentByCode.emit({
      paymentItem: {},
      needEmitEvent,
      isResetSelectedTokenInfo: true,
    })
  }

  const setSelectedPaymentForBinCoupon = ({
    paymentCode,
    tokenItem,
    changeCheckData = {},
    cb,
  }: SetSelectedPaymentForBinCouponParams) => {
    debuggerLog('setSelectedPaymentForBinCoupon====', paymentCode, tokenItem, tokenItem?.id, changeCheckData, handleEditableInfoChange)
    if (tokenItem?.id) {
      // handleSelectedToken({
      //   tokenItem,
      //   nowPay: paymentInfo,
      //   changeCheckData,
      // })

      const nowChangeData = {
        ...paymentStore?.editablePaymentInfo?.[paymentCode],
        card_token_info: tokenItem,
        changeCheckData,
      }

      paymentStore?.mergeState?.('editablePaymentInfo', {
        [paymentCode]: nowChangeData,
        extraInfo: {
          isNeedPostCheckout: true,
        },
      })

      paymentStore.paymentEventBus.paymentInfoChange.emit({
        ...nowChangeData,
        extraInfo: {
          changeCheckData,
          isNeedPostCheckout: true,
        },
      })
    }

    if (paymentStore?.selectedPayment?.code !== paymentCode) {
      setSelectedPayment({
        paymentInfo: paymentStore.payments?.find(item => item?.code === paymentCode),
        changeCheckData,
      })
    }

    cb && cb()
  }

  const checkUnflodSelectBank = (item: Trade_PayToolKit.PaymentInfoItem) => {
    if (item?.bank_list?.length) {
      const bankInfo = paymentStore.editablePaymentInfo[item.code]
      if (!bankInfo?.bank_name) return true
    }
    return false
  }

  const getCardTokenInfo = (selectedPayment: Trade_PayToolKit.SelectedPaymentInfo) => {
    const returnInfo = {
      card_token_info: selectedPayment.card_token,
      token_id: '',
      installment_token_id: '',
    }
    const editablePaymentInfo = paymentStore.editablePaymentInfo?.[selectedPayment.code]
    if (editablePaymentInfo?.card_token_info?.id) {
      returnInfo.card_token_info = editablePaymentInfo.card_token_info
      if (selectedPayment.code === 'routepay-cardinstallment') {
        returnInfo.installment_token_id = returnInfo.card_token_info?.id || ''
      }
      if (selectedPayment.code === 'routepay-card') {
        returnInfo.token_id = returnInfo.card_token_info?.id || ''
      }
    } else {
      // 删除了所有 token 后
      returnInfo.card_token_info = {}
    }

    debuggerLog('editablePaymentInfo >>>>>', editablePaymentInfo)

    // 卡分期 token 前置时选择了 use new card
    if (editablePaymentInfo?.isUseNewCard) {
      returnInfo.card_token_info = {}
      returnInfo.installment_token_id = ''
    }
    return returnInfo
  }

  const handleSelectedPayment = (params?: {item?: Trade_PayToolKit.PaymentInfoItem; manualSelect?: boolean }) => {
    const { item, manualSelect = false } = params || {}
    const selectedPayment = item || paymentStore.selectedPayment
    if (!manualSelect && checkUnflodSelectBank(selectedPayment)) {
      const bankList = selectedPayment.bank_list?.sort?.((a, b) => a.is_gray - b.is_gray)
      paymentStore.updateState('bankSelectInfo', {
        visible: true,
        banks: bankList,
        selectedBank: {},
        paymentCode: selectedPayment.code,
      })
    }
  }

  const checkShowChannelBtn = (payment: Trade_PayToolKit.PaymentInfoItem) => {
    const editablePaymentInfo = paymentStore.editablePaymentInfo?.[payment.code] || {}
    const sign_account_info = payment.paymentSignUp?.[0] || {}

    if (payment.code === 'PayPal-GApaypal' && (editablePaymentInfo?.use_one_time_sign || !sign_account_info.signUpEmail)) {
      return true
    }
    // fix applepay btn sdk仅支持safari15+版本
    if (+payment?.is_apple_pay === 1 && paymentStore.abtInfo?.ApplepayDropoffOptimization?.param?.ApplepayButtonShow === 'Show' && isSafariVersionGTE15()) {
      return true
    }
    return false
  }

  const checkoutUseSync = (payment: Trade_PayToolKit.PaymentInfoItem) => {
    if (payment.is_apple_pay === 1) return true
    if (payment.inlineChannel === 'paypal') {
      if (payment.code === 'PayPal-GApaypal' && !checkShowChannelBtn(payment)) return false
      return true
    }
    return false
  }

  // 根据特定支付方式组装支付信息
  // 注意！！！！payment可能是个空对象，需要做好判断！！！！！
  const getAssembledInfoByPayment = (payment: Partial<Trade_PayToolKit.PaymentInfoItem>) => {
    const editablePaymentInfo = paymentStore.editablePaymentInfo?.[payment.code]

    const sign_account_info = payment.paymentSignUp?.[0] || {}

    const cardTokenInfo = getCardTokenInfo(payment)
    const assembledInfo = {
      origin_payment_info: payment,
      payment_code: payment.code || '',
      bank_code: editablePaymentInfo?.bank_code || '',
      bank_name: editablePaymentInfo?.bank_name || '',
      pay_phone: editablePaymentInfo?.pay_phone || '',
      pay_email: editablePaymentInfo?.pay_email || '',
      cpf_number: '',
      payment_id: payment.id || '',
      payment_type: payment.payment_type || '',
      payment_logo: payment.logo_url || '',
      payment_title: payment.title || '',
      display_logo: editablePaymentInfo?.display_logo || '',
      display_title: editablePaymentInfo?.display_title || '',
      noCardRoutepayAction: payment.noCardRoutepayAction,
      use_sync: checkoutUseSync(payment),
      show_channel_btn: checkShowChannelBtn(payment),
      sign_account_info,
      card_token_info: cardTokenInfo.card_token_info || {},
      token_id: cardTokenInfo.token_id || '',
      installment_token_id: cardTokenInfo.installment_token_id,
      is_apple_pay: payment.is_apple_pay,
      manualSelect: !!payment.manualSelect,
      signupFlag: sign_account_info?.id ? false : editablePaymentInfo?.signupFlag,
      use_one_time_sign: editablePaymentInfo?.use_one_time_sign,
    }

    return assembledInfo
  }

  // 组装支付信息给外部消费方
  const handleAssembledToOutsidePaymentInfo = (rewriteInfo?: Trade_PayToolKit.AssembledToOutsidePaymentInfo) => {
    const selectedPayment = paymentStore.selectedPayment
    debuggerLog('handleAssembledToOutsidePaymentInfo selectedPayment >>>>', selectedPayment)
    const assembledInfo = getAssembledInfoByPayment(selectedPayment)

    const assembledToOutsidePaymentInfo: Trade_PayToolKit.AssembledToOutsidePaymentInfo = {
      ...assembledInfo,
      ...rewriteInfo,
    }
    paymentStore.mergeState('selectedPayment', assembledToOutsidePaymentInfo)
    debuggerLog('paymentStore.selectedPayment >>>>', paymentStore.selectedPayment)
    return paymentStore.selectedPayment
  }

  return {
    handleSelectedPayment,
    setSelectedPayment,
    getAssembledInfoByPayment,
    handleAssembledToOutsidePaymentInfo,
    setSelectedPaymentForBinCoupon,
    resetSelectedPayment,
  }
}
