<template>
  <div
    v-expose="{
      id: 'expose_payment_method.comp_pay-toolkit',
      data: paymentItemExposeAnalysis,
      prefix: EXPOSE_PREFIX.PAYMENT_ITEM,
    }"
    class="bs-payment-item"
    :data-p-c="paymentItem.code || 'bnpl'"
    :data-p-e="paymentItem.enabled"
  >
    <PaymentItemBase
      v-expose="exposePaypalData(paymentItem)"
      :title="paymentTitle"
      :description="paymentItem.description"
      :payment-code="paymentItem.code"
      :payment-id="paymentItem.id"
      :enabled="paymentItem.enabled"
      :logo-url="paymentLogo"
      :is-selected="isSelected"
      @select="handleSelect"
    >
      <template #center-top-right>
        <!-- <div class="">center-top-right</div> -->
        <PaymentTips
          :show="showTips"
          @click-doubt="handleClickDialogDoubt(+paymentItem.enabled === 1 ? paymentItem.description : paymentItem.gray_description)"
        />
      </template>
      <template #center-bottom>
        <!-- 默认支付方式tag -->
        <PaymentTags
          v-if="paymentTags && paymentTags.length"
          class="payment-tags"
          :tags="paymentTags"
        />
        <!-- 不支持支付红色文案展示 -->
        <PaymentWarningInfo
          v-if="paymentItem.not_support_prime_auto_renewal_tip"
          :warning="paymentItem.not_support_prime_auto_renewal_tip"
        />
        <!-- 分期描述信息 -->
        <div
          v-if="showInstallmentDescription"
          class="installment-description"
        >
          <PaymentDescription
            :show-description="showInstallmentDescription"
            :description="paymentItem.front_show_desc"
            :active="true"
            :use-native-tag="true"
          >
            <template #icon>
              <PaymentTips
                :tag-type="'span'"
                :show="!!paymentItem.front_show_link"
                @click-doubt="handleClickLinkDoubt(paymentItem.front_show_link)"
              />
            </template>
          </PaymentDescription>
        </div>

        <PaypalMessage
          v-if="paymentItem.code === 'PayPal-bnpl' && !showInstallmentDescription"
          :total-price-amount="totalPriceAmount"
        />

        <DiscountBlock
          :payments-preferential-tips="nowPaymentsPreferentialTips"
          :payment-item="paymentItem"
          :now-pay-bin="nowPayBin"
          :coupon-list-state="couponListState"
          scene="payment_list"
          @click-tip="handleShowCardBinArticle"
          @handle-bs-payment-action="tempHandleBsPaymentAction"
        />

        <SignupTermInfo
          v-if="isSelected && paymentItem.enabled"
          :terms-link="paymentItem.termsLink"
        />

        <!-- 选中后再展示的内容 -->
        <template v-if="isSelected">
          <ChannelPaymentSignUp
            v-if="showSignup"
            :sign-up-selected="signUpInfo?.signupFlag"
            :desc="paymentItem.rememberAccountTip"
            :enabled="paymentItem.enabled"
            @select="handleSelectPaymentSignUp"
          />
          <ItemInfoBnplGraph
            scene="checkout"
            :payment-code="paymentItem.code"
            :options="paymentItem.installments_visualization"
          />
        </template>

        <PaymentDescription
          :show-description="enabledPaymentDescription"
          :description="descriptionStr"
          :active="descriptionActiveShow"
        />
        <KlarnaInlineWidget
          v-if="visibleKlarnaInlineWidget"
        />
        <PaymentLogoList
          v-if="logoList && logoList.length"
          :card-logo-list="logoList"
        >
          <template #change-to>
            <span
              v-if="isNeedShowHomogenizationLogoTitle"
              class="change-to"
            >
              {{ language.BS_KEY_PAY_1070 }}
            </span>
          </template>
          <template #discount>
            <div
              v-if="isShowHomogenizationDiscount"
              class="homogenization-discount"
            >
              <span>{{ paymentItem.homogenization_discount_tip }}</span>
            </div>
          </template>
          <template #right-slot>
            <Icon
              v-if="isShowHomogenizationDiscount || isHomoGenizationPay"
              name="sui_icon_more_down_12px_2"
              :is-rotate="cssRight"
            />
          </template>
          <template #bottom-slot>
            <DiscountItem
              v-if="isHomogenizationMeetDiscountTip"
              :payments-preferential-tips-item="homogenizationMeetDiscountTip" />
          </template>
        </PaymentLogoList>
      </template>
      <template #right>
        <Icon
          v-if="isShowToken"
          name="sui_icon_more_right_16px_2"
          size="16px"
          :is-rotate="cssRight"
          @click.stop="showTokenDrawer"
        />
        <ItemSelectBank
          v-if="visibleBankSelect && !isHomoGenizationPay"
          :text="language.BS_KEY_PAY_1091"
          :isHadSelectedBank="isHadSelectedBank"
          @click="handleToSelectBank"
        />
        <SignupPayOneTimeDrawer
          v-if="paymentItem.supportOneTime === '1' && isSelected && !isPrimeOrSavePopup"
          :language="language"
          :payment-code="paymentItem.code"
          :is-force-remember="isForceRemember"
          :force-remember-text="forceRememberText"
          :use-one-time-pay="signUpInfo?.use_one_time_sign"
          :one-times-save-tip-key="paymentItem.oneTimesSaveTipKey"
          :one-times-tip-key="paymentItem.oneTimesTipKey"
          :contact-msg="paymentItem.contactMsg"
          @confirm="handleSelectOneTimePay"
        />
      </template>
    </PaymentItemBase>
    <ClientOnly>
      <CodTipsDialog
        v-if="paymentItem.code === 'cod'"
        ref="codTipsRef"
        :desc-popup="paymentItem.descPopup || {}"
      />
      <LazyMount v-if="showCommonDialog">
        <SDialog
          append-to-body
          :visible.sync="showCommonDialog"
        >
          <div>{{ commonDialogContent }}</div>
          <template #footer>
            <SButton
              :type="['primary']"
              :width="'100%'"
              @click="destroyCommonDialog"
            >
              {{ language.BS_KEY_PAY_1141 }}
            </SButton>
          </template>
        </SDialog>
      </LazyMount>
    </ClientOnly>
  </div>
</template>

<script name="BPayPaymentItem" setup lang="ts">
import ClientOnly from 'vue-client-only'
import { Icon } from '@shein-aidc/icon-vue2'
import PaymentItemBase from './PaymentItemBase.vue'
import PaymentLogoList from './PaymentLogoList.vue'
import DiscountBlock from './DiscountBlock.vue'
import ItemInfoBnplGraph from './ItemInfoBnplGraph.vue'
import ChannelPaymentSignUp from './ChannelPaymentSignUp.vue'
import SignupTermInfo from './SignupTermInfo.vue'
import PaymentDescription from './PaymentDescription.vue'
import PaymentWarningInfo from './PaymentWarningInfo.vue'
import PaymentTips from './PaymentTips.vue'
import PaypalMessage from './PaypalMessage.vue'
import PaymentTags from './PaymentTags.vue'
import { usePaymentItem } from '../../../../hooks/usePaymentItem'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { Dialog as SDialog, Button as SButton, Toast as $toast, LazyMount } from '@shein/sui-mobile'
import { computed, ref, defineAsyncComponent, onMounted, type DefineComponent, ComponentPublicInstance, inject, nextTick, watch } from 'vue'
import { type LangKeys } from '../../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { AS_PayToolkit } from '../../../../types'
import { debuggerLog } from '../../../../utils'
import DiscountItem from './DiscountItem.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { EXPOSE_PREFIX } from '../../../../common/constants'

const { vExpose, triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const CodTipsDialog = defineAsyncComponent(() => import('./CodTipsDialog.vue'))
const SignupPayOneTimeDrawer = defineAsyncComponent(() => import('./SignupPayOneTimeDrawer.vue'))
const ItemSelectBank = defineAsyncComponent(() => import('./ItemSelectBank.vue'))
const KlarnaInlineWidget = defineAsyncComponent(() => import('./KlarnaInlineWidget.vue'))

interface BPayPaymentItemProps {
  paymentItem: Trade_PayToolKit.PaymentInfoItem;
  isSelected: boolean;
  language: Record<LangKeys, string>;
  signUpInfo?: {
    signupFlag: string;
    use_one_time_sign: boolean;
  };
  isFolded?: boolean;
  paymentIndex?: number;
  isOnlyPayItem?: boolean; // 判断是否是单独用了 payment-item 这个组件 目前用于楼层置底
  nowEditablePaymentInfoData?: Record<string, Trade_PayToolKit.EditablePaymentItem>;
  nowPaymentItemsConfig?: Record<string, any>;
  forceSignupTip?: string;
  couponListState?: Record<string, any>;
  nowSelectedDisPlayBankInfo?: Record<string, any>;
  paypalMessageShow?: boolean;
  totalPriceAmount?: string | number;
  paymentItemConfig?: { // 暂未使用
    isOnlyPayItem?: boolean;
    isFolded?: boolean;
    isNestedPaymentItem: boolean; // 是否是嵌套弹窗中的item，目前特指bnpl二级选择弹窗
  }
}

const props = withDefaults(defineProps<BPayPaymentItemProps>(), {
  paymentItem: () => ({} as Trade_PayToolKit.PaymentInfoItem),
  isSelected: false,
  signUpInfo: () => ({
    signupFlag: '0',
    use_one_time_sign: false,
  }),
  isOnlyPayItem: false,
  paypalMessageShow: false,
})

const emits = defineEmits(['select', 'manual-selected-info', 'editable-info-change', 'handle-bs-payment-action'])

const paymentsStore = usePaymentsStore()

const { cssRight } = useAppConfigs()?.$envs || {}

const {
  hasSelected,
  paymentTitle,
  paymentLogo,
  paymentTags,
  isShowToken,
  visibleBankSelect,
  showSignup,
  signAccountInfo,
  handleToSelectBank,
  isHadSelectedBank,
  isHomoGenizationPay,
  isPrimeOrSavePopup,
  paymentItemExposeAnalysis,
  nowUseEditablePaymentInfoData,
  isForceRemember,
  forceRememberText,
} = usePaymentItem(props)

const codTipsRef = ref<ComponentPublicInstance<DefineComponent<typeof CodTipsDialog>>>()

const showCommonDialog = ref(false)

const commonDialogContent = ref('')

const logoList = computed(() => {
  const childPayments = props?.paymentItem?.payments || []
  if (childPayments?.length) {
    return childPayments.reduce<string[]>((acc, cur) => [...acc, cur.enabled == '1' ? cur.logo_url : cur.gray_logo_url], [])
  }
  return props?.paymentItem?.card_logo_list || []
})

// 小问号展示条件
const showTips = computed(() => {
  if(props.paymentItem.code === 'cod'){
    const descPopup = props.paymentItem?.descPopup
    return descPopup && typeof descPopup === 'object' && !Array.isArray(descPopup) && Object.keys(descPopup).length > 0
  }

  if (props.paymentItem.show_description == '1' && !props.isSelected) {
    if (+props.paymentItem.enabled === 0) {
      return !!props.paymentItem.gray_description
    } else {
      return !!props.paymentItem.description
    }
  } else {
    return false
  }
})

// 描述文案
const descriptionStr = computed(() => {
  return props.paymentItem.enabled == '1' ? props.paymentItem.description : props.paymentItem.gray_description
})

// 是否显示分期描述信息
const showInstallmentDescription = computed(() => {
  if (props.paymentItem.code !== 'PayPal-bnpl') {
    return !!props.paymentItem.front_show_desc
  }
  return !props.paypalMessageShow
})

// 是否支持描述文案
const enabledPaymentDescription = computed(() => {
  return props.paymentItem.show_description == '1' && !!descriptionStr.value
})

// 展示隐藏支付方式描述
const descriptionActiveShow = computed(() => {
  return props.isSelected || props.paymentItem.enabled != '1' && props.paymentItem.code === 'cod'
})

const isShowHomogenizationDiscount = computed(() => !!props?.paymentItem?.homogenization_discount_tip)
const isNeedShowHomogenizationLogoTitle = computed(() => !!props?.paymentItem?.payments?.length && props.isSelected)
const isHomogenizationMeetDiscountTip = computed(() => props?.paymentItem?.homogenization_meet_discount_tip || '')
const homogenizationMeetDiscountTip = computed(() => {
  return {
    titles: [props?.paymentItem?.homogenization_meet_discount_tip || ''],
  }
})
const nowPaymentsPreferentialTips = computed(() => {
  const isBnpl = props?.paymentItem?.payments?.length && props?.paymentItem.payments.length > 0
  if (isBnpl) {
    return props?.paymentItem?.payments?.find(item => props?.paymentItem?.code === item?.code)?.paymentsPreferentialTips
  }
  return props?.paymentItem.paymentsPreferentialTips
})

const nowPayBin = computed(() => {
  return nowUseEditablePaymentInfoData?.value?.card_token_info?.card_bin || ''
})

const visibleKlarnaInlineWidget = computed(() => {
  return props.paymentItem.code === 'klarna-sliceitdirect' && props.isSelected && !props.isOnlyPayItem
})

const showCommonTips = (content: string) => {
  commonDialogContent.value = content
  showCommonDialog.value = true
}

const destroyCommonDialog = () => {
  showCommonDialog.value = false
}

const handleSelect = () => {
  debuggerLog('handleSelect==|||=====', props.paymentItem, props.paymentItem.enabled, props.paymentItem.code, props.isSelected)
  const isNoBuyNowPayLater = !((props.paymentItem?.payments || [])?.length > 0) // 非bnpl支付方式
  // 支付方式不可用或已选中当前支付方式时不重复触发
  if (props.paymentItem.code && props.isSelected && isNoBuyNowPayLater) return
  if (+props.paymentItem.enabled === 0) {
    // 选择置灰方式，且有置灰文案, 展示置灰文案tips
    if (props.paymentItem.gray_description) {
      showCommonTips(props.paymentItem.gray_description)
      if (props.paymentItem.grayReason === 'currency') {
        triggerNotice({
          id: 'expose_popup_notsupportcash.simple',
        })
      }
    }
    return
  }

  emits('select', { paymentItem: props.paymentItem })
  hasSelected.value = true
  handleSetOrderShowInfo()
}

const handleSelectPaymentSignUp = (prevSignUp: string) => {
  if (prevSignUp === '1' && isForceRemember.value) {
    $toast(forceRememberText.value)
    return
  }
  const changeInfo: Trade_PayToolKit.EditablePaymentItem = {
    signupFlag: prevSignUp === '1' ? '0' : '1',
  }



  handleEditableInfoChange(changeInfo)
  if (props.paymentItem.code === 'PayPal-GApaypal') {
    clickPopupVaultingChangeConfirmBi({ signupFlag: changeInfo.signupFlag })
  }
}

const handleSelectOneTimePay = ({ selected }: { selected: boolean }) => {
  const changeInfo: Trade_PayToolKit.EditablePaymentItem = {
    use_one_time_sign: selected,
  }
  // emits('editable-info-change', {
  //   key: props.paymentItem.code,
  //   info: changeInfo,
  // })
  handleEditableInfoChange(changeInfo)
}

const handleClickDialogDoubt = (content: string) => {
  if (props.paymentItem.code === 'cod') {
    codTipsRef.value?.openDialog()
  } else {
    showCommonTips(content)
  }
}

const handleClickLinkDoubt = (url: string) => {
  // 跳转url
  window.location.href = url
}

const handleShowCardBinArticle = (articleId: string) => {
  // 如果是链接，直接跳转
  const linkReg = /^https?:\/\//
  if (linkReg.test(articleId)) {
    location.href = articleId
    return
  }

  paymentsStore?.updateState('cardBinArticleInfo', {
    visible: true,
    articleId,
  })
}

const showTokenDrawer = () => {
  paymentsStore?.updateState('isShowTokenDrawer', true)
  paymentsStore?.updateState('showTokenDrawerPay', props.paymentItem.code)
}

const handleEditableInfoChange = (changeInfo) => {
  emits('editable-info-change', {
    key: props.paymentItem.code,
    info: changeInfo,
  })
}

const exposePaypalData = (paymentItem: Trade_PayToolKit.PaymentInfoItem) => {
  if (paymentItem?.code !== 'PayPal-GApaypal') return {}
  return {
    id: 'expose_is_vaulting.comp_pay-toolkit',
    data: {
      payment_method: paymentItem.code,
      is_paypal: paymentItemExposeAnalysis.value?.is_vaulting ? '1' : '0',
      is_vaulting_account: signAccountInfo.value?.signUpEmail ? '1' : '0',
      is_vaulting_status: paymentItemExposeAnalysis.value?.is_vaulting ? '1' : '0',
    },
  }
}

const tempHandleBsPaymentAction = (data) => {
  // console.log('tempHandleBsPaymentAction===', data)
  emits('handle-bs-payment-action', data)
}

const clickPopupVaultingChangeConfirmBi = ({ signupFlag, use_one_time_sign }: { signupFlag?: string; use_one_time_sign?: boolean }) => {
  let type = ''

  if (showSignup.value) {
    if (signupFlag === '1') {
      if (signAccountInfo.value?.signUpEmail) {
        type = 'vaulting'
      } else {
        type = 'payandsave'
      }
    } else {
      if (signAccountInfo.value?.signUpEmail) {
        type = 'vaultingtoGA'
      } else {
        type = 'novaultingtoGA'
      }
    }
  }
  if (props.paymentItem.supportOneTime === '1') {
    if (!use_one_time_sign) {
      if (signAccountInfo.value?.signUpEmail) {
        type = 'vaulting'
      } else {
        type = 'payandsave'
      }
    } else {
      if (signAccountInfo.value?.signUpEmail) {
        type = 'vaultingtoGA'
      } else {
        type = 'novaultingtoGA'
      }
    }
  }

  triggerNotice({
    id: 'click_paypalvaulting_change_confirm:simple',
    data: {
      type,
    },
  })
}

const paymentItemExposeBI = () => {
  if (
    props.paymentItem.code === 'PayPal-GApaypal' &&
    !!signAccountInfo.value?.id
  ) {
    triggerNotice({
      id: 'expose_paypal_vaulting:simple',
    })
  }
}

const handleSetOrderShowInfo = () => {
  paymentsStore?.paymentEventBus?.setOrderShowInfo?.emit({
      paymentTitle: paymentsStore?.selectedPayment?.isUseNewCard ? paymentsStore?.selectedPayment?.title : paymentTitle.value,
      paymentLogo: paymentsStore?.selectedPayment?.isUseNewCard ? paymentsStore?.selectedPayment?.web_logo : paymentLogo.value,
  })
}

watch(() => paymentTitle.value, () => {
  if (props.isSelected) {
      handleSetOrderShowInfo()
    }
})

onMounted(() => {
  paymentItemExposeBI()

  nextTick(() => {
    if (props.isSelected) {
      handleSetOrderShowInfo()
    }
  })
})

</script>

<style lang="less" scoped>
.border-dpr(@border,@num,@color) {
    @{border}: @num * 0.5 solid @color;
}
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}
.homogenization-discount {
  color: #c44a01;
  font-size: 10px;
  padding: 4/75rem 8/75rem;
  .font-dpr(20px);
  // line-height: 1;
  .border-dpr(border, 1px, #ffe1db);
  background-color: #fff6f3;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.homogenization-block {
  /deep/.discount-tips-item {
    background-color: unset;
    border: unset;
  }
}

.payment-tags {
  margin-top: 4/75rem;
}

.installment-description {
  display: flex;
  flex-wrap: wrap;
}
.change-to {
  color: #222222;
  font-size: 12px;
  font-family: SF UI Text;
  font-weight: 400;
  display: inline-block;
  width: 60 * 2/75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4 * 2/75rem;
  flex-shrink: 0;
}
.bs-payment-item {
  &:last-child {
    .bs-payment-item__base {
      border-bottom: none;
    }
  }
  .item-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
