import { reactive, readonly, provide, watch, computed, inject, onMounted, nextTick, ref, onActivated } from 'vue'
import useApis from './useApis'
import { type Manager } from '@shein-aidc/bs-sdk-libs-manager'
import { PAY_TOOLKIT_PAYMENTS_INJECT_KEY } from '../common/constants'
import { useStoreAction } from './useStoreAction'
import { useDdc } from './useDdc'
import { type LangKeys } from '../common/languages'
import type { StoreAction } from './useStoreAction'
import type { PaymentItemsConfig } from '../types'
import { type AbtPosKeysTypes } from '../common/constants'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { checkPaymentMethodSupport, debuggerLog } from '../utils'
import { createEventBus, type UseEventBusReturn } from './usePaymentEventBus'
import { mergeSelectPaymentWithDeleteUndefined } from '../utils'
import { CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { USE_NEW_CARD_SYMBOL } from './usePaymentItems'

export interface PaymentsState {
  language: Record<LangKeys, string>;
  selectedPayment: Trade_PayToolKit.SelectedPaymentInfo;
  bnplInfo: {
    payments: Trade_PayToolKit.PaymentInfoItem[];
    selectedPayment: Trade_PayToolKit.PaymentInfoItem;
    visibleBnpl: boolean;
  };
  editablePaymentInfo: Trade_PayToolKit.EditablePaymentInfo;
  isTemporaryPayment: boolean;
  temporaryPayment: Trade_PayToolKit.PaymentInfoItem;
  paymentItemsConfig: PaymentItemsConfig;
  isShowTokenDrawer: boolean;
  bankSelectInfo: {
    visible: boolean;
    banks: Trade_PayToolKit.BankInfo[];
    selectedBank?: Trade_PayToolKit.BankInfo;
    paymentCode: string;
    selectedDisPlayBankInfo?: Record<string, any>;
  };
  capitecDialogConfig: Trade_PayToolKit.ICapitecDialogConfig;
  showTokenDrawerPay: string;
  paySafeIconsList: string[];
  payments: Trade_PayToolKit.PaymentInfoItem[];
  abtInfo: Record<AbtPosKeysTypes, Trade_PayToolKit.AbtInfo>;
  checkoutInfo: Trade_PayToolKit.CheckoutInfo;
  paymentSuggestion?: string;
  showGooglePayDialog: boolean;
  showApplePayDialog: boolean;
  couponListState: Record<string, any>;
  paymentEventBus: Record<string, UseEventBusReturn<any, any>>;
  userInfoGetters: Manager.ToFunc<Trade_PayToolKit.UserInfoGetter>;
  isMouted: boolean;
  cardBinArticleInfo: {
    visible: Boolean,
    articleId: String,
  },
  installmentInfo: Record<string, any>;
  selectedTokenInfo: Record<string, any>;
  payInfoForOrderShow: Record<string, any>;
  handleBsPaymentAction: (info?: {action: Trade_PayToolKit.BsPaymentAction, payload?: Record<string, any>}) => void;
}

export interface PaymentsStore extends StoreAction<keyof PaymentsState>, PaymentsState {}

const createPaymentsStore = (): PaymentsStore => {
  const defaultPaymentsStoreInfo: PaymentsStore = {
    language: {} as Record<LangKeys, string>,
    selectedPayment: {} as Trade_PayToolKit.SelectedPaymentInfo,
    bnplInfo: {
      payments: [],
      selectedPayment: {} as Trade_PayToolKit.PaymentInfoItem,
      visibleBnpl: false,
    },
    editablePaymentInfo: {},
    isTemporaryPayment: false,
    temporaryPayment: {} as Trade_PayToolKit.PaymentInfoItem,
    paymentItemsConfig: {
      foldedPosition: 0,
    },
    isShowTokenDrawer: false,
    bankSelectInfo: {
      visible: false,
      banks: [],
      selectedBank: undefined,
      paymentCode: '',
    },
    capitecDialogConfig: {},
    payments: [],
    showTokenDrawerPay: '',
    paySafeIconsList: [],
    abtInfo: {} as Record<AbtPosKeysTypes, Trade_PayToolKit.AbtInfo>,
    checkoutInfo: {} as Trade_PayToolKit.CheckoutInfo,
    paymentSuggestion: '',
    showApplePayDialog: false,
    showGooglePayDialog: false,
    couponListState: {} as Record<string, any>,
    paymentEventBus: createEventBus(),
    userInfoGetters: {} as Manager.ToFunc<Trade_PayToolKit.UserInfoGetter>,
    isMouted: false,
    cardBinArticleInfo: {
      visible: false,
      articleId: '',
    },
    installmentInfo: {},
    selectedTokenInfo: {} as Record<string, any>,
    payInfoForOrderShow: {} as Record<string, any>,
    handleBsPaymentAction: () => {},
    mergeState: () => {},
    updateState: () => {},
  }
  return defaultPaymentsStoreInfo
}

function findPayment(data: Trade_PayToolKit.PaymentInfoItem[], predicate: (v: Trade_PayToolKit.PaymentInfoItem) => boolean): Trade_PayToolKit.SelectedPaymentInfo {
  const queue = [...data] // 使用队列来处理广度优先搜索
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) return node // 找到匹配的节点，返回该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return null as any // 没有找到匹配的节点，返回 null
}


function iteratePaymentsClientSupport(payments: Trade_PayToolKit.PaymentInfoItem[], handleIteratePayment?: (payment: Trade_PayToolKit.PaymentInfoItem) => void, config?: { isMounted }): Trade_PayToolKit.PaymentInfoItem[] {
  return payments?.filter?.((payment) => {
    const { payments } = payment || {}
    if (payments?.length) {
      return {
        ...payment,
        payments: iteratePaymentsClientSupport(payments, undefined, config),
      }
    } else {
      if (!checkPaymentMethodSupport(payment, { isMounted: config?.isMounted })) return false
      if (handleIteratePayment) {
        handleIteratePayment(payment)
      }
    }
    return payment
  }) || payments
}

// const getPaymentOptionsConfig =

function buildGetters(getters: Manager.InitConfigs['getters']) {
  return Object.keys(getters).reduce((curr, next) => {
    curr[next] = computed(getters[next])
    return curr
  }, {} as Trade_PayToolKit.UserInfoGetter)
}

const getDefaultBankPayment = (pay: Trade_PayToolKit.PaymentInfoItem) => {
  let nowBankPay = pay || {}
  if (pay.payments?.length) {
    nowBankPay = findPayment(pay.payments, v => v.last_payment)
  }
  const nowDefaultBank = nowBankPay?.bank_list?.find?.(v => v?.default_selected)
  return {
    isHadDefaultBankPayment: +nowBankPay?.enabled === 1 && nowBankPay?.last_payment && nowDefaultBank,
    nowDefaultBank,
  }
}

// 获取默认选中的银行
const getIsHadDefaultBankPayment = (pay: Trade_PayToolKit.PaymentInfoItem) => {
  const nowDefaultBank = pay?.bank_list?.find?.(v => v?.default_selected)
  return +pay?.enabled === 1 && pay?.last_payment && nowDefaultBank
}

const getDefaultPayment = (props, { isMounted }): Trade_PayToolKit.SelectedPaymentInfo => {
  const selectedPayment = findPayment(props.paymentInfo?.payments || [], v => v.code === props.paymentInfo?.paymentSuggestion && +v.enabled === 1 || getIsHadDefaultBankPayment(v)) || {}
  if (!checkPaymentMethodSupport(selectedPayment, { isMounted })) return {} as Trade_PayToolKit.SelectedPaymentInfo
  return selectedPayment
}

const getDefaultTokenPayment = ({ props, selectedPayment, language }) => {
  debuggerLog('getDefaultTokenPayment >>>')
  if (props?.paymentConfig?.checkoutType === CHECKOUT_TYPE?.NORMAL_AGAIN) {
    if (props?.checkoutInfo?.token_id !== selectedPayment?.card_token?.id && selectedPayment?.card_token_list?.length > 0) {
      return {[selectedPayment?.code]: {
        isUseNewCard: true, // 使用新卡
        id: USE_NEW_CARD_SYMBOL,
        preferentialTipsOfToken: {
          paymentsPreferentialTips: selectedPayment?.paymentsPreferentialTips || [],
        },
        title: selectedPayment?.code === 'routepay-cardinstallment' ? language.BS_KEY_PAY_1218 : language.BS_KEY_PAY_1217,
        payCode: selectedPayment?.code,
      }}
    }
    return {
      [selectedPayment?.code]: {...(selectedPayment?.card_token || {})},
    }
  }

  return {}
}

// eslint-disable-next-line max-lines-per-function
const usePayments = <T>(props, ctx?: T) => {
  const { getLanguageInfo, getAbtInfoApi } = useApis()
  // const appConfigs = useAppConfigs()
  // debuggerLog('props', props, ctx)
  debuggerLog('usePayments===props===>>>', props, ctx)
  const isMounted = ref(false)

  const selectedPayment = getDefaultPayment(props, { isMounted: isMounted.value })

  const nowPaymentStoreInfo = {
    ...createPaymentsStore(),
    language: props.injectFsData?.language || {},
    selectedPayment: selectedPayment,
    selectedTokenInfo: getDefaultTokenPayment({ props, selectedPayment, language: props.injectFsData?.language || {} }),
    payments: iteratePaymentsClientSupport(props.paymentInfo?.payments || []),
    checkoutInfo: props.checkoutInfo || {},
    userInfoGetters: buildGetters(props.userInfoGetter || {}),
    paymentSuggestion: selectedPayment.code || '',
    abtInfo: props?.injectFsData?.abtInfo || {},
    paymentItemsConfig: {
      foldedPosition: +props.paymentInfo?.folded_position || 0,
      forceRememberCardTip: props.paymentInfo?.force_remember_card_tip || null,
      checkoutForceRememberCardTip: props.paymentInfo?.checkout_force_remember_card_tip || null,
    },
  }

  const paymentsStore = reactive<PaymentsStore>(nowPaymentStoreInfo)

  const { mergeState, updateState } = useStoreAction<keyof PaymentsState>(paymentsStore)
  paymentsStore.mergeState = mergeState
  paymentsStore.updateState = updateState

  const language = computed(() => paymentsStore.language)

  const ddcParams = computed(() => {
    if (paymentsStore.selectedPayment.jwt && paymentsStore.selectedPayment.formActionUrl) {
      return {
        action: paymentsStore.selectedPayment.formActionUrl,
        jwt: paymentsStore.selectedPayment.jwt,
        cardBin: paymentsStore.selectedPayment.card_token_info?.card_bin || '',
      }
    }
    return {
      action: '',
      jwt: '',
      cardBin: '',
    }
  })

  const { sendDdc } = useDdc()

  const handleDdc = ({ submit, callback }: { submit: () => void; callback: (v: string) => void }) => {
    if (paymentsStore.selectedPayment.jwt && paymentsStore.selectedPayment.formActionUrl) {
      sendDdc({
        params: ddcParams.value,
        submit,
        callback: (sessionId) => {
          if (paymentsStore.selectedPayment.jwt && paymentsStore.selectedPayment.formActionUrl) {
            callback(sessionId)
            paymentsStore.mergeState('selectedPayment', { ddcSessionId: sessionId || '' })
          }
          debuggerLog('sessionId==||==' + paymentsStore.selectedPayment.code + '>>', sessionId)
        },
      })
    }
  }

  provide<PaymentsStore>(PAY_TOOLKIT_PAYMENTS_INJECT_KEY, readonly(paymentsStore) as any)

  const initData = async () => {
    const { abtInfo: fsAbtInfo, language: fsLanguage } = props.injectFsData || {}
    debuggerLog('initData==entry====', Object.keys(fsAbtInfo || {})?.length, Object.keys(fsLanguage || {})?.length)
    const [
      abtInfo,
      languageInfo,
    ] = await Promise.all([
      Object.keys(fsAbtInfo || {})?.length ? { info: { pos: fsAbtInfo } } : getAbtInfoApi(),
      // TODO 暂时重复请求一次
      Object.keys(fsLanguage || {})?.length ? fsLanguage : getLanguageInfo(),
      // getLanguageInfo(),
    ])
    debuggerLog('initd-==｜｜====', abtInfo, fsAbtInfo, fsLanguage)
    mergeState('abtInfo', Object.assign({}, abtInfo.info?.pos || {}))
    mergeState('language', languageInfo)
    debuggerLog('initData===>>>', props)
  }

  const initPaymentsData = (payments: Trade_PayToolKit.PaymentInfoItem[]) => {
    payments.forEach?.((payment) => {
      if (payment.payments?.length) {
        initPaymentsData(payment.payments)
      } else {
        initEditablePaymentInfo(payment)
      }
    })
  }

  // 只在第一次组件挂载时初始化，用于一些不随后续接口发生改变的数据，比如签约的rememberStatus
  const initEditablePaymentInfo = (payment: Trade_PayToolKit.PaymentInfoItem) => {
    const { needToSign, supportOneTime, rememberStatus, card_token, bank_list, collect_email, collect_phone_number } = payment
    const needForceSignUp = props.paymentConfig.forceSignUp && (props.paymentInfo.force_remember_card_tip || props.paymentInfo.checkout_force_remember_card_tip)
    let paymentEditableInfo = {}
    // 需要签约
    if (needToSign === '1' || supportOneTime === '1') {
      paymentEditableInfo = {
        ...paymentEditableInfo,
        use_one_time_sign: false,
        signupFlag: rememberStatus || (needForceSignUp ? '1' : '0'),
      }
    }
    // 需要选择银行
    const needSelectBank = bank_list?.length
    if (needSelectBank) {
      paymentEditableInfo = {
        ...paymentEditableInfo,
        bank_code: '',
        bank_name: '',
        pay_phone: '',
        pay_email: '',
      }
    }
    const needCollectInfo = collect_email === '1' || collect_phone_number === '1'
    if (needCollectInfo) {
      paymentEditableInfo = {
        ...paymentEditableInfo,
        pay_phone: '',
        pay_email: '',
      }
    }
    if (card_token) {
      paymentEditableInfo = {
        ...paymentEditableInfo,
        card_token_info: card_token,
      }
    }
    const isEditableInfoEmpty = Object.keys(paymentEditableInfo).length === 0
    if (!isEditableInfoEmpty) {
      mergeState('editablePaymentInfo', {
        [payment.code]: paymentEditableInfo as Trade_PayToolKit.EditablePaymentInfoItem,
      })
    }
  }

  // 初始化默认选中支付方式的银行信息
  const initBankInfo = () => {
    const {
      nowDefaultBank,
      isHadDefaultBankPayment,
    } = getDefaultBankPayment(paymentsStore.selectedPayment)

    if (isHadDefaultBankPayment) {
      mergeState('bankSelectInfo', {
        visible: false,
        selectedBank: nowDefaultBank || {},
        banks: paymentsStore.selectedPayment?.bank_list || [],
        paymentCode: paymentsStore.selectedPayment?.code || '',
      })

      mergeState('editablePaymentInfo', {
        [paymentsStore.selectedPayment?.code]: {
          display_logo: nowDefaultBank?.logo || '',
          display_title: nowDefaultBank?.name || '',
          bank_code: nowDefaultBank?.code || '',
          bank_name: nowDefaultBank?.name || '',
          pay_phone: '',
          pay_email: '',
        },
      })
    }

    return {
      isHadDefaultBankPayment,
    }
  }

  const changeSelectPayByCheckout = () => {
    if (paymentsStore.selectedPayment.code) {
      const matchSelectedPayment = findPayment(props?.paymentInfo?.payments || [], v => v.code === paymentsStore.selectedPayment.code && +v.enabled === 1) || {}
      paymentsStore?.updateState('selectedPayment', mergeSelectPaymentWithDeleteUndefined(paymentsStore.selectedPayment, matchSelectedPayment || {}))
    }
  }

  const handleClientPaymentSupportCheck = () => {
    const payments = iteratePaymentsClientSupport(paymentsStore.payments || [], undefined, { isMounted: true })
    const matchSelectedPayment = findPayment(payments || [], v => v.code === paymentsStore.selectedPayment.code && +v.enabled === 1) || {}
    // 如果当前选中的支付方式不支持，则清空选中的支付方式
    if (!matchSelectedPayment.code) {
      updateState('selectedPayment', {})
    }
    updateState('payments', payments)
  }

  // setup阶段执行
  const init = () => {
    // ssr场景下数据初始化
    initPaymentsData(props.paymentInfo?.payments || [])
  }

  watch(() => props.couponListState, () => {
    debuggerLog('couponListState====', props.couponListState)
    const tempObj = {}
    Object.keys(props.couponListState || {}).forEach((key) => {
      if (Array.isArray(props.couponListState[key])) {
        tempObj[key] = props.couponListState[key]
      }
    })
    updateState('couponListState', tempObj)
  }, {
    immediate: true,
    deep: true,
  })

  watch(() => props.paymentInfo, () => {
    debuggerLog('props.paymentInfo watch===>>>', props.paymentInfo)
    const payments = iteratePaymentsClientSupport(props.paymentInfo?.payments || [], (pay) => {
      if (pay?.card_token) {
        const editablePaymentInfo = paymentsStore.editablePaymentInfo?.[pay.code]
        debuggerLog('editablePaymentInfo====', pay.code, pay?.card_token, editablePaymentInfo, paymentsStore)
        let tempCardTokenInfo = editablePaymentInfo?.isUseNewCard ? {} : {...pay?.card_token}
        // 二次下单支付数据更新
        if (paymentsStore?.selectedTokenInfo?.[pay?.code]) {
          tempCardTokenInfo = editablePaymentInfo?.isUseNewCard || paymentsStore?.selectedTokenInfo?.[pay?.code]?.isUseNewCard ? {} : {
            ...pay?.card_token,
            ...(paymentsStore?.selectedTokenInfo?.[pay?.code] || {}),
          }
        }
        mergeState('editablePaymentInfo', {
          [pay.code]: {
            ...editablePaymentInfo,
            card_token_info: tempCardTokenInfo,
          },
        })
      }
    }, { isMounted: isMounted.value })

    // const newSelectedPaymentInfo = findPayment(payments || [], v => v.code === paymentsStore.selectedPayment?.code && +v.enabled === 1) || {}
    paymentsStore.updateState('bnplInfo', {
      ...paymentsStore.bnplInfo,
      selectedPayment: {},
    })
    updateState('payments', payments)
    changeSelectPayByCheckout()
    // updateState('selectedPayment', mergeSelectPaymentWithDeleteUndefined(paymentsStore.selectedPayment, newSelectedPaymentInfo || {}))
  })

  // watch(() => props.paymentInfo?.payments, () => {
  // })

  watch(() => props.paymentInfo.paymentSuggestion, (val, oldVal) => {
    debuggerLog('props.paymentInfo.paymentSuggestion====', val, oldVal)
    // 如果当前已选中了支付方式，且新返回的paymentSuggestion为空则不处理，此场景应该使用resetSelectPayment来重置选中态
    if (val === oldVal || (!val && !!paymentsStore.selectedPayment.code)) return
    const payment = getDefaultPayment(props, { isMounted: isMounted.value })
    nextTick(() => {
      updateState('paymentSuggestion', payment.code || '')
      updateState('selectedPayment', payment || {})
      paymentsStore.paymentEventBus.setSelectedPaymentByCode.emit({
        paymentItem: payment,
        needEmitEvent: payment?.code ? true : false, // 推荐的支付方式不支持时，需重置当前支付方式并重置
      })
    })
  })

  watch(() => props.checkoutInfo, () => {
    debuggerLog('checkoutInfo====', props.checkoutInfo)
    mergeState('checkoutInfo', props.checkoutInfo)
  })

  watch([
    () => props.paymentInfo?.force_remember_card_tip,
    () => props.paymentInfo?.checkout_force_remember_card_tip,
    () => props.paymentInfo?.folded_position,
    () => props.paymentInfo?.paySafeIcons,
  ], () => {
    mergeState('paymentItemsConfig', {
      foldedPosition: +props.paymentInfo?.folded_position || 0,
      forceRememberCardTip: props.paymentInfo?.force_remember_card_tip || null,
      checkoutForceRememberCardTip: props.paymentInfo?.checkout_force_remember_card_tip || null,
    })
    updateState('paySafeIconsList', props.paymentInfo?.paySafeIcons)
  })

  init()
  onMounted(async () => {
    // 端侧处理不支持的支付方式，如applepay
    handleClientPaymentSupportCheck()
    await initData()
    // 如果是非ssr场景，无直出数据，则需要等待initData返回数据后再初始化
    initPaymentsData(props.paymentInfo?.payments || [])

    const { isHadDefaultBankPayment } = initBankInfo()
    debuggerLog('onMounted init payment====', paymentsStore)
    // 前提是有推荐的支付方式
    if (props.paymentInfo?.paymentSuggestion || isHadDefaultBankPayment) {
      paymentsStore.paymentEventBus.setSelectedPaymentByCode.emit({
        paymentItem: paymentsStore.selectedPayment || {},
        tokenItem: paymentsStore.selectedPayment?.card_token || {},
        // 如果当前选中的支付方式为空或者与推荐的支付方式不一致，则外部需重新调用checkout接口
        needEmitEvent: paymentsStore.selectedPayment?.code !== props.paymentInfo?.paymentSuggestion ? true : false,
        isInitEmit: true,
      })
    }
  })

  onActivated(async () => {
    // 端侧处理不支持的支付方式，如applepay
    handleClientPaymentSupportCheck()
    await initData()
    // 如果是非ssr场景，无直出数据，则需要等待initData返回数据后再初始化
    initPaymentsData(props.paymentInfo?.payments || [])

    const { isHadDefaultBankPayment } = initBankInfo()
    debuggerLog('onMounted init payment====', paymentsStore)
    // 前提是有推荐的支付方式
    if (props.paymentInfo?.paymentSuggestion || isHadDefaultBankPayment) {
      paymentsStore.paymentEventBus.setSelectedPaymentByCode.emit({
        paymentItem: paymentsStore.selectedPayment || {},
        tokenItem: paymentsStore.selectedPayment?.card_token || {},
        // 如果当前选中的支付方式为空或者与推荐的支付方式不一致，则外部需重新调用checkout接口
        needEmitEvent: paymentsStore.selectedPayment?.code !== props.paymentInfo?.paymentSuggestion ? true : false,
      })
    }
  })

  return {
    isMounted,
    language,
    paymentsStore,
    handleDdc,
    ddcParams,
  }
}

export const usePaymentsStore = () => {
  return inject<PaymentsStore>(PAY_TOOLKIT_PAYMENTS_INJECT_KEY, createPaymentsStore())
}

export default usePayments
